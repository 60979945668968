import env from '@ui/env';
import cn from '@ui/utils/cn';
import React from 'react';

type ProseProps = React.ComponentProps<'div'> & {
  size?: 'default' | 'small';
};

const Prose = React.forwardRef<HTMLDivElement, ProseProps>(({ className, size = 'default', ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      env.RETURNS_PREVIEW ? 'tracking-[0.4px] leading-[1.86] space-y-4' : 'tracking-[0.084px] leading-[1.86] space-y-4',
      '[&_a]:underline [&_a]:text-black',
      '[&_h1]:font-bold [&_h1]:tracking-[0.005em]',
      '[&_h2]:font-semibold [&_h2]:tracking-[0.005em]',
      env.RETURNS_PREVIEW
        ? '[&_blockquote]:bg-[#FAEAF0] [&_blockquote]:px-6 [&_blockquote]:py-4 [&_blockquote]:rounded-lg'
        : '[&_blockquote]:bg-brand-lightpink2 [&_blockquote]:px-4 [&_blockquote]:py-2',
      env.RETURNS_PREVIEW
        ? '[&_ul]:list-disc [&_ul]:list-outside [&_ul]:ml-5 [&_ul]:space-y-2'
        : '[&_ul]:list-disc [&_ul]:list-outside [&_ul]:ml-4 [&_ul]:space-y-2',
      env.RETURNS_PREVIEW
        ? '[&_ol]:list-decimal [&_ol_ol]:list-[lower-alpha] [&_ol_ol]:mt-2 [&_ol]:list-outside [&_ol]:ml-5 [&_ol]:space-y-2'
        : '[&_ol]:list-decimal [&_ol_ol]:list-[lower-alpha] [&_ol_ol]:mt-2 [&_ol]:list-outside [&_ol]:ml-4 [&_ol]:space-y-2 [&_ol>li]:marker:font-bold',
      size === 'default' && 'text-base',
      size === 'small' && env.FAQ_PREVIEW && 'text-sm tracking-[0.006em] leading-6',
      size === 'small' && !env.FAQ_PREVIEW && 'text-sm',
      className,
    )}
    {...props}
  />
));

Prose.displayName = 'Prose';

export default Prose;
