import React from 'react';
import Prose from '@ui/components/core/prose';
import { Container } from '@ui/components/core/container';
import { MetricSelector } from '@ui/components/shared';
import env from '@ui/env';
import { useStaticContent, StaticContent } from '@ui/providers/static-content-provider';
import cn from '@ui/utils/cn';
import { unzip } from 'lodash';
import Image from '@ui/components/core/image';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from '@ui/components/core/breadcrumb';

export const Models = [
  {
    name: 'Adison',
    tag: 'model_adison',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_ADISON_AU.png?v=1725583963',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_ADISON_US_edf07da3-9bc0-4734-a4d1-54cfe9f77146.png?v=1730097606',
  },
  {
    name: 'Amelia',
    tag: 'model_amelia',
    dress_size: 'XS',
    image_au:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_AMELIA_AU_55b659ae-348e-4eac-b901-d9e3d2f9eafb.png?v=1725583963',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_AMELIA_US_51e2d733-5e51-4ed3-97cc-409c1e612474.png?v=1730097606',
  },
  {
    name: 'Ashton',
    tag: 'model_ashton',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/MODEL_ASHTON_AU.png?v=1739947146',
    image_us: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/MODEL_ASHTON_US.png?v=1739947146',
  },
  {
    name: 'Ava',
    tag: 'model_ava',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_AVA_AU.png?v=1725583963',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_AVA_US_0a357883-5415-4cb9-91ed-bf3374d1b857.png?v=1730097606',
  },
  {
    name: 'Belle-Lucia',
    tag: 'model_belle-lucia',
    dress_size: 'XS',
    image_au:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_BELLE_AU_687269b2-b91f-47dc-a9d0-9685180c86fe.png?v=1725583961',
    image_us: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_BELLA-LUCIA-US.png?v=1730097606',
  },
  {
    name: 'Carolina',
    tag: 'model_carolina',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_CAROLINA_AU.png?v=1725583962',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_CAROLINA_US_f689e185-eaee-478e-981d-886152905c01.png?v=1730097606',
  },
  {
    name: 'Casey',
    tag: 'model_casey',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_CASEY_AU.png?v=1725583961',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_CASEY_US_df7cbe94-032c-4efc-9d59-3e7fcd95741e.png?v=1730097606',
  },
  {
    name: 'Cathlin',
    tag: 'model_cathlin',
    dress_size: 'XS',
    image_au:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_CATHLIN_AU_297267a3-17c0-476e-8273-759a5f6961f9.png?v=1725583962',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_CATHLIN_US_8dedc164-f6ef-412d-a4fc-a968e90846f2.png?v=1730097606',
  },
  {
    name: 'Cecilia',
    tag: 'model_cecilia',
    dress_size: 'XS',
    image_au:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_CECELIA_AU_cb1a19b0-ca27-4afe-b35b-ed27747ce338.png?v=1725583963',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_CECELIA_US_965ec1c3-5c2c-41ed-8641-5fc8dc5cde5c.png?v=1730097606',
  },
  {
    name: 'Crystal',
    tag: 'model_crystal',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_CRYSTAL_AU.png?v=1727652884',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_CRYSTAL_US_560d0c43-979d-4dd9-aaae-749874c6eed8.png?v=1730097606',
  },
  {
    name: 'Daia',
    tag: 'model_daia',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_DAIA_AU.png?v=1725583963',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_DAIA_US_cc2197db-0d2d-4bd1-a6c4-582fa28cf01f.png?v=1730097606',
  },
  {
    name: 'Deni',
    tag: 'model_deni',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_DENI_AU.png?v=1725583962',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_DENI_US_989a4b3a-3900-4b3b-8e22-d1aa70e61fcd.png?v=1730097606',
  },
  {
    name: 'Dominique',
    tag: 'model_dominique',
    dress_size: 'XS',
    image_au:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_DOM_AU_5def698f-4b08-4d0d-bbc1-d6add781d5fb.png?v=1725583963',
    image_us: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_DOMINIQUE_US.png?v=1730097606',
  },
  {
    name: 'Emily',
    tag: 'model_emily',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_EMILY_AU.png?v=1725583963',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_EMILY_US_78c1208e-c327-4c25-890d-777a7c95f56c.png?v=1730097606',
  },
  {
    name: 'Freya',
    tag: 'model_freya',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_FREYA_AU.png?v=1739151711',
    image_us: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_FREYA_US.png?v=1739151711',
  },
  {
    name: 'Grace',
    tag: 'model_grace',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_GRACE_AU.png?v=1739151711',
    image_us: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_GRACE_US.png?v=1739151711',
  },
  {
    name: 'Jayde',
    tag: 'model_jayde',
    dress_size: 'S',
    image_au:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_JAYDE_AU_9c16ddbd-1705-4a2d-b8a7-7a100af0c270.png?v=1725583961',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_JAYDE_US_45dd2f50-e3a8-4825-b9ca-3190c0fc4abb.png?v=1730097606',
  },
  {
    name: 'Jemma',
    tag: 'model_jemma',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_JEMMA_AU.png?v=1725583962',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_JEMMA_US_e1a3917f-e612-494f-9873-c3c82e25072c.png?v=1730097606',
  },
  {
    name: 'Katie',
    tag: 'model_katie',
    dress_size: 'S',
    image_au:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_KATIE_AU_770bb65d-a4e5-46b1-a027-20c7e5150b86.png?v=1725583962',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_KATIE_US_c7b468d0-d35e-4383-8520-f117404fd465.png?v=1730097606',
  },
  {
    name: 'Kiana',
    tag: 'model_kiana',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_KIANA_AU.png?v=1725583962',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_KIANA_US_98a34465-8aff-470d-b297-ee7cd4a10f47.png?v=1730097607',
  },
  {
    name: 'Kinsey',
    tag: 'model_kinsey',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_KINSEY_AU.png?v=1725583962',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_KINSEY_US_5787d986-f948-4ab0-928c-7dd3807ff756.png?v=1730097606',
  },
  {
    name: 'Leah',
    tag: 'model_leah',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_LEAH_AU.png?v=1739151711',
    image_us: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_LEAH_US.png?v=1739151711',
  },
  {
    name: 'Leidy',
    tag: 'model_leidy',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_LEIDY_AU.png?v=1725583963',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_LEIDY_US_8e011a29-f4e9-44b3-a9a0-c347a2573d94.png?v=1730097606',
  },
  {
    name: 'Lyndl',
    tag: 'model_lyndl',
    dress_size: 'M',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_LYNDL_AU.png?v=1725583963',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_LYNDL_US_eb3ed40b-f9c2-4b4a-9fe9-07e7bb22e3a3.png?v=1730097607',
  },
  {
    name: 'Michaela',
    tag: 'model_michaela',
    dress_size: 'S',
    image_au:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_MICHAELA_AU_e7b7675e-094a-41e6-b75e-934457c8802b.png?v=1725583962',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_MICHAELA_US_a66272dc-3f48-4962-994e-3283b3ed598a.png?v=1730097606',
  },
  {
    name: 'Natalie',
    tag: 'model_natalie',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_NATALIE_AU.png?v=1725583961',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_NATALIE_US_311a5f43-52a9-485c-8b41-16d129e310b0.png?v=1730097606',
  },
  {
    name: 'Nathayla',
    tag: 'model_nathayla',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_NATHAYLA_AU.png?v=1725583963',
    image_us: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_NATHALYA_US.png?v=1730097606',
  },
  {
    name: 'Paris',
    tag: 'model_paris',
    dress_size: 'S',
    image_au:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_PARIS_AU_93fb03a4-a388-400b-a26e-0ddff972e5d1.png?v=1725583962',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_PARIS_US_2722b0d4-fa7b-4a4a-8412-806cafca4fb6.png?v=1730097606',
  },
  {
    name: 'Pixie',
    tag: 'model_pixie',
    dress_size: 'XS',
    image_au:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_PIXIE_AU_16c62acc-7468-4b0b-9934-74b247478e49.png?v=1725583963',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_PIXIE_US_36ac4e5e-9135-4796-9e2e-f2fe4727532e.png?v=1730097606',
  },
  {
    name: 'Renee',
    tag: 'model_renee',
    dress_size: 'XS',
    image_au:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_RENEE_AU_6c72b6ad-caea-4a9e-8297-5cc10eaebefc.png?v=1725583962',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_RENEE_US_fa9d1f0a-a52c-41f6-80fc-d800b07e3aee.png?v=1730097606',
  },
  {
    name: 'Rochelle',
    tag: 'model_rochelle',
    dress_size: 'XS',
    image_au:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_ROCHELLE_AU_f7837dec-94b7-4e97-bd47-bfde6551d63e.png?v=1725583962',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_ROCHELLE_US_ee8218ae-636e-451e-a3c7-b61d1c5dfa12.png?v=1730097606',
  },
  {
    name: 'Saskia',
    tag: 'model_saskia',
    dress_size: 'XS',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_SASKIA_AU.png?v=1725583963',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_SASKIA_US_ccdd96ee-85f8-441f-962f-10f5ee6e2054.png?v=1730097606',
  },
  {
    name: 'Tori',
    tag: 'model_tori',
    dress_size: 'S',
    image_au: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_TORI_AU.png?v=1725583963',
    image_us:
      'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_TORI_US_09af5e91-dd5b-40ab-9b86-603cc2ededcd.png?v=1730097606',
  },
];

const SizeGuidePage = () => {
  const [metric, setMetric] = React.useState(env.NEXT_PUBLIC_REGION === 'US' ? 'in' : 'cm');

  const clothingSizes = useStaticContent('SizeGuide.ClothingSizes');
  const sizeConversions = useStaticContent('SizeGuide.SizeConversions');
  const shoeSizes = useStaticContent('SizeGuide.ShoeSizes');

  return (
    <>
      <div className="border-b border-black">
        <section className="py-3 max-w-[1800px] mx-auto px-3 md:px-8">
          <Breadcrumb className='text-black'>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink className="text-xxs md:text-sm capitalize tracking-[0.55px] uppercase" href={"/"}>
                  Home
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator className="text-xxs md:text-sm capitalize tracking-[0.55px]">/</BreadcrumbSeparator>
              <BreadcrumbItem>
                <BreadcrumbLink className="text-xxs md:text-sm capitalize tracking-[0.55px] uppercase" href={"/pages/size-guide"}>
                  Size Guide
                </BreadcrumbLink>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </section>
      </div>
    

    <Container className="mt-3">
      <div className="pt-8 pb-4">
        <h1 className="text-lg font-bold tracking-[0.25px] uppercase">Size Guide</h1>
      </div>
      <Prose>
        <StaticContent id="SizeGuide.SizeGuideMessage" />
      </Prose>
      <div className="mt-10">
        <h1 className="font-semibold tracking-[0.004em] uppercase">Clothing size</h1>
        <Prose className="mt-4">
          Hello Molly uses standard {env.NEXT_PUBLIC_REGION === 'NZ' ? 'NZ/AU' : 'Australian'} sizes. Please refer to
          the conversion table below as a guide.
        </Prose>
        <div className="mt-4">
          <StyledTable>
            <thead>
              <tr>
                {clothingSizes[0].map((col) => (
                  <th key={col} className="first:w-[107px]">
                    {col}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {clothingSizes.slice(1).map((row) => (
                <tr key={row[0]}>
                  <td className="bg-pink-2 font-semibold">{row[0]}</td>
                  <td>{row[1]}</td>
                  <td>{row[2]}</td>
                  <td>{row[3]}</td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </div>
      </div>
      <div className="mt-10">
        <h1 className="font-semibold tracking-[0.004em] uppercase">Size Conversion</h1>
        <div className="mt-4">
          <StyledTable>
            <thead>
              <tr>
                {sizeConversions[0].map((col) => (
                  <th key={col} className="first:w-[107px]">
                    {col}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sizeConversions.slice(1).map((row) => (
                <tr key={row[0]}>
                  <td className="bg-pink-2 font-semibold">{row[0]}</td>
                  <td>{row[1]}</td>
                  <td>{row[2]}</td>
                  <td>{row[3]}</td>
                  <td>{row[4]}</td>
                  <td>{row[5]}</td>
                  <td>{row[6]}</td>
                  <td>{row[7]}</td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </div>
      </div>
      <div className="mt-10">
        <h1 className="font-semibold tracking-[0.004em] uppercase">Shoe</h1>
        <div className="mt-4">
          <StyledTable>
            <thead>
              <tr>
                {unzip(shoeSizes)[0].map((col) => (
                  <th key={col}>{col}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {unzip(shoeSizes)
                .slice(1)
                .map((row, i) => (
                  <tr key={i}>
                    <td>{row[0]}</td>
                    <td>{row[1]}</td>
                    <td>{row[2]}</td>
                    <td>{row[3]}</td>
                  </tr>
                ))}
            </tbody>
          </StyledTable>
        </div>
      </div>
      <div className="mt-10">
        <h1 className="font-semibold tracking-[0.004em] uppercase">How to measure</h1>
        <p className="text-sm tracking-[0.004em] mt-2">
          Ensure the measuring tape is level and not too tight to get an accurate measurement.
        </p>
        <div className="mt-4 flex items-center max-w-2xl">
          <div className="space-y-6 w-3/5">
            <div className="space-y-2">
              <div className="text-sm tracking-[0.6px] font-bold">A. BUST</div>
              <div className="text-sm tracking-[0.6px] leading-6">
                Measure around the fullest part of your bust, typically across the nipples
              </div>
            </div>
            <div className="space-y-2">
              <div className="text-sm tracking-[0.6px] font-bold">B. HIPS</div>
              <div className="text-sm tracking-[0.6px] leading-6">
                Measure around the fullest part of your hips for accurate measurement.
              </div>
            </div>
            <div className="space-y-2">
              <div className="text-sm tracking-[0.6px] font-bold">C. WAIST</div>
              <div className="text-sm tracking-[0.6px] leading-6">Measure around your natural waistline.</div>
            </div>
          </div>
          <div className="w-2/5">
            <Image
              src="https://cdn.shopify.com/s/files/1/0281/2071/1254/files/how-to-measure.png?v=1724374931"
              alt="how to measure sample"
              className="w-full h-auto"
              width={412}
              height={810}
            />
          </div>
        </div>
      </div>
      <div className="mt-10">
        <div className="flex items-center justify-between">
          <h1 className="font-semibold tracking-[0.004em] uppercase">Model Size</h1>
          <MetricSelector value={metric} onChange={setMetric} />
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-12">
          {Models.map((model) => (
            <div key={model.tag}>
              <div className="uppercase text-center text-sm font-bold tracking-[0.004em]">{model.name}</div>
              <div className="text-center text-sm tracking-[0.004em]">Wearing size {model.dress_size}</div>
              <div className="px-6 pb-9">
                <img
                  className={cn('w-full mx-auto h-auto', metric === 'cm' ? 'block' : 'hidden')}
                  src={model.image_au}
                  alt="model au measurements"
                  width={2183}
                  height={3277}
                  loading={metric === 'cm' ? 'eager' : 'lazy'}
                  fetchPriority={metric === 'cm' ? 'high' : 'low'}
                />
                <img
                  className={cn('w-full mx-auto h-auto', metric === 'in' ? 'block' : 'hidden')}
                  src={model.image_us}
                  alt="model us measurements"
                  width={2183}
                  height={3277}
                  loading={metric === 'in' ? 'eager' : 'lazy'}
                  fetchPriority={metric === 'in' ? 'high' : 'low'}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
    </>
  );
};

export default SizeGuidePage;

function StyledTable({ className, children }: { className?: string; children: React.ReactNode }) {
  return (
    <table
      className={cn(
        'w-full overflow-hidden',
        '[&_th]:p-3 [&_th]:border [&_th]:border-[#E0E0E0] [&_th]:text-center [&_th]:font-bold [&_th]:text-sm [&_th]:tracking-[0.004em] [&_th]:bg-pink-2',
        '[&_td]:px-3 [&_td]:py-2 [&_td]:border [&_td]:border-[#E0E0E0] [&_td]:text-center [&_td]:text-sm [&_td]:tracking-[0.004em] [&_td]:relative',
        '[&_td:hover]:after:bg-brand-lightpink2 [&_td:hover]:after:absolute [&_td:hover]:after:left-0 [&_td:hover]:after:w-full [&_td:hover]:after:-z-10 [&_td:hover]:after:top-[-5000px] [&_td:hover]:after:h-[10000px]',
        '[&_tr:hover_td:not(:hover)]:bg-brand-lightpink2 [&_td:hover]:bg-pink-10',
        className,
      )}
    >
      {children}
    </table>
  );
}
