import { create } from 'zustand';
import { WishlistFrontEnd } from '@ui/types/contextObjects';
import { getWishlist } from '@ui/axios/wishlistKing/getWishlist';
import { addWishlistProduct, AddWishlistProductArgs } from '@ui/axios/wishlistKing/addWishlistProduct';
import { removeWishlistProduct, RemoveWishlistProductArgs } from '@ui/axios/wishlistKing/removeWishlistProduct';
import { createSelectors } from './selectors';

interface WishlistAsyncState {
  wishlist: WishlistFrontEnd | {};
  wishlistSessionId: string;
  getWishlist: (customerId: string | undefined) => Promise<void>;
  addWishlistProduct: ({
    customerId,
    productId,
    variantId,
  }: Omit<AddWishlistProductArgs, 'sessionId'>) => Promise<void>;
  removeWishlistProduct: ({ customerId, productId }: Omit<RemoveWishlistProductArgs, 'sessionId'>) => Promise<void>;
  setWishlistSession: () => void;
  loading: boolean;
}

const useWishlistStoreAsync = create<WishlistAsyncState>((set, get) => ({
  wishlist: {},
  wishlistSessionId: '',
  loading: false,
  getWishlist: async (customerId) => {
    if (get().wishlistSessionId === '') get().setWishlistSession();
    const sessionId = get().wishlistSessionId;
    const response = await getWishlist({
      customerId,
      sessionId,
    });
    set({ wishlist: response });
  },
  addWishlistProduct: async ({ customerId, productId, variantId }) => {
    const sessionId = get().wishlistSessionId;
    set({ loading: true }); // Set loading to true before the operation
    try {
      await addWishlistProduct({ customerId, sessionId, productId, variantId });
      const response = await getWishlist({ customerId, sessionId });
      set({ wishlist: response });
    } finally {
      set({ loading: false }); // Reset loading state after the operation
    }
  },
  removeWishlistProduct: async ({ customerId, productId }) => {
    const sessionId = get().wishlistSessionId;
    set({ loading: true }); // Set loading to true before the operation
    try {
      await removeWishlistProduct({ customerId, sessionId, productId });
      const response = await getWishlist({ customerId, sessionId });
      set({ wishlist: response });
    } finally {
      set({ loading: false }); // Reset loading state after the operation
    }
  },
  setWishlistSession: () => set({ wishlistSessionId: Math.random().toString(20).substring(2, 15) }),
}));

export const wishlistSelectors = {
  wishlistStore: createSelectors(useWishlistStoreAsync),
};

export default useWishlistStoreAsync;
