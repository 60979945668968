import * as React from "react"
import * as CheckboxPrimitive from "@radix-ui/react-checkbox"

import cn from "@ui/utils/cn"
import CheckIcon from "@ui/components/core/Icons/Common/CheckIcon";

interface CheckboxProps extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  className?: string;
  containerClassName?: string;
  label?: string;
  labelClass?: string;
  id: string;
}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ 
  id,
  className,
  containerClassName, 
  label,
  labelClass,
  ...props }, ref) => (
  <div className={cn("flex items-center gap-3",containerClassName)}>
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        "peer h-4 w-4 shrink-0 rounded-[0.25rem] border border-black ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground",
        className
      )}
      id={id}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn("-mt-[1px] -ml-[1px] w-4 h-4 flex items-center justify-center text-current bg-pink-10 rounded-[0.25rem]")}
      >
        <CheckIcon className="h-3 w-3" color="#fff" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
    {label && <label htmlFor={id} className={cn("text-xs font-medium text-black leading-4",labelClass)}>
      {label}
    </label>}
  </div>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
