import React from 'react';
import cn from '@ui/utils/cn';
import { Button } from "@ui/components/core/button"
import WhereIsMySizeSheet from '../../sheets/WhereIsMySizeSheet/WhereIsMySizeSheet';
import type { Product, Variant } from '@ui/types/models/Product';
import env from '@ui/env';

type SizeSelectorProps = {
  product: Product;
  value: Variant | undefined;
  setVariant: (value: Variant | undefined) => void;
  layout?: 'flex' | 'grid';
};

export const Sizes: Record<string, Record<string, string>> = {
  AU: {
    XXS: 'XXS (AU4)',
    XS: 'XS (AU6)',
    S: 'S (AU8)',
    M: 'M (AU10)',
    L: 'L (AU12)',
    XL: 'XL (AU14)',
  },
};

const SizeSelector = ({ product, value, setVariant, layout }: SizeSelectorProps): React.ReactElement => {
  const [openWhereIsMySize, setOpenWhereIsMySize] = React.useState(false);
  const [outOfStockSize, setOutOfStockSize] = React.useState<Variant>();

  function handleClick(variant: Variant) {
    if (product.variants && isDisabled(variant)) {
      setOutOfStockSize(variant);
      setOpenWhereIsMySize(true);
    } else if (variant.id === value?.id) {
      setVariant(undefined);
    } else {
      setVariant(variant);
    }
  }

  return (
    <>
      {layout === 'grid' ? (
        <div className="grid grid-cols-4 md:max-xl:grid-cols-3 w-full gap-4">
          {product.variants?.map((variant) => (
            <Button
              key={variant.id}
              variant="outline"
              className="h-10 p-2.5 !text-xs tracking-[0.18px] font-medium whitespace-nowrap"
              muted={isDisabled(variant)}
              checked={variant.id === value?.id}
              onClick={() => handleClick(variant)}
            >
              {Sizes[env.NEXT_PUBLIC_REGION][variant.size] ?? variant.size}
            </Button>
          ))}
        </div>
      ) : (
        <div className="flex flex-wrap gap-4">
          {product.variants?.map((variant) => (
            <Button
              key={variant.id}
              variant="outline"
              className={cn(
                'size-11 border text-sm font-normal tracking-[0.8px] p-0',
                variant.size === 'One Size' && 'px-4 w-auto',
              )}
              muted={isDisabled(variant)}
              checked={variant.id === value?.id}
              onClick={() => handleClick(variant)}
            >
              {variant.size}
            </Button>
          ))}
        </div>
      )}

      <WhereIsMySizeSheet
        product={product}
        open={openWhereIsMySize}
        initialVariantId={outOfStockSize?.id}
        onClose={() => {
          setOpenWhereIsMySize(false);
          setOutOfStockSize(undefined);
        }}
      />
    </>
  );
};

export default SizeSelector;

function isDisabled(variant?: Variant): boolean {
  if (!variant) return false;
  return Number(variant.quantityAvailable) < 1 || !variant.availableForSale;
}

export function shouldUseGridLayout(product: Product) {
  return (
    Object.keys(Sizes).includes(env.NEXT_PUBLIC_REGION) &&
    product.variants.some((variant) => ['XXS', 'XS', 'S', 'M', 'L', 'XL'].includes(variant.size)) &&
    product.productType === 'Clothes' &&
    !product.tags.includes('category_pets') // hide size measurements for pet-related items
  );
}
