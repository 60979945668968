import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

import cn from "@ui/utils/cn"

const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-black text-white hover:bg-black/80",
        secondary:
          "border-transparent bg-white text-black hover:bg-black/20",
        filled: "w-5 h-5 bg-brand-darkpink text-white border-white border-[2px] p-1.5 flex items-center justify-center",
        destructive:
          "border-transparent rounded-sm bg-red-1 text-white hover:bg-red-1/80",
        outline: "text-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants }
