import cn from '@ui/utils/cn';
import React from 'react';

type HelperTextProps = React.HTMLAttributes<HTMLElement> & {
  component?: any;
  invalid?: boolean;
  disabled?: boolean;
};

const HelperText = React.forwardRef<HTMLElement, HelperTextProps>(
  ({ className, component: Component = 'div', children, invalid, disabled, ...props }, ref) => {
    return (
      <Component
        ref={ref}
        {...props}
        data-invalid={invalid || undefined}
        data-disabled={disabled || undefined}
        className={cn(
          'text-[11px] text-[#424242] tracking-[0.004em] px-2',
          'data-[invalid]:text-state-error-primary',
          'data-[disabled]:text-[#9E9E9E]',
          className,
        )}
      >
        {children}
      </Component>
    );
  },
);

HelperText.displayName = 'HelperText';

export default HelperText;
