import React from 'react';
import { Badge } from '@ui/components/core/badge';
import cn from '@ui/utils/cn';

type NotificationBadgeProps = {
  children: React.ReactNode;
  label?: React.ReactNode;
  show: boolean;
  animate: boolean;
  className?: string;
  containerClassName?: string;
};

const NotificationBadge = React.forwardRef<HTMLDivElement | null, NotificationBadgeProps>(
  ({ show, animate, className = "",containerClassName = "", label, children }, ref): React.ReactElement => {

    return (
      <div ref={ref} className={cn('relative overflow-visible',containerClassName)}>
        {children}
        <Badge variant="filled" className={cn("absolute top-0 right-0 p-0 text-[10px] pointer-events-none", 
          show ? 'translate-x-1/2 -translate-y-1/2 opacity-1' : 
          'opacity-0' ,
          animate ? 'animate-grow' : 'animate-none',
        className)}>
          {label}
        </Badge>
      </div>
    );
  },
);

NotificationBadge.displayName = 'NotificationBadge';

NotificationBadge.defaultProps = {
  label: undefined,
};

export default NotificationBadge;
