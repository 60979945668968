import React, { useEffect } from 'react';
import { DownChevronIcon } from '@ui/components/core';
import Dialog from "@ui/components/core/dialog"
import { useQuery } from '@tanstack/react-query';
import env from '@ui/env';
import Image from '@ui/components/core/image';
import { Button } from "@ui/components/core/button"
import { GetLocalizations } from '@client-shopify/gql/storefront/api/queries';
import cn from '@ui/utils/cn';
import { sortBy } from 'lodash';
import { useRouter } from 'next/router';
import useCustomerLocation from '@ui/hooks/useCustomerLocation';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@ui/components/core/dropdownMenu';

const LocationDialog = () => {
  const [open, setOpen] = React.useState(false);

  const customerLocation = useCustomerLocation();

  const { data: localizations } = useQuery({
    queryKey: ['localizations'],
    queryFn: async () => (await GetLocalizations()).data,
  });

  const availableCountries = React.useMemo(() => localizations?.localization.availableCountries || [], [localizations]);
  const customerLocaleFromIp = React.useMemo(
    () => {
      const customerCountry = customerLocation?.country || env.NEXT_PUBLIC_NODE_ENV === 'development' ? "AU" : undefined;
      return availableCountries.find((c) => c.isoCode === customerCountry)
    },
    [availableCountries, customerLocation],
  );

  useEffect(() => {
    if (!customerLocation) return;

    // Do not show the modal if the customer matches the store's country
    if (customerLocation.country === env.NEXT_PUBLIC_REGION) return;

    // Do not show the modal on next page load if the customer already selected a country from this modal
    if (window.localStorage.getItem('customerCountry')) return;

    // Do not show the modal if the locale can not be determined from the available countries
    if (!customerLocaleFromIp) return;

    setOpen(true);
  }, [customerLocation, customerLocaleFromIp]);

  const [tab, setTab] = React.useState<number>(0);
  const [locale, setLocale] = React.useState<(typeof availableCountries)[0]>();

  const router = useRouter();

  const handleSubmit = async (countryCode: string) => {
    localStorage.setItem('customerCountry', countryCode);
    router.reload();
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>

      <Dialog.Content
        className="max-w-sm rounded shadow max-md:mb-0"
        onInteractOutside={(e) => e.preventDefault()}
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
        aria-describedby="location-dialog"
      >
        <Dialog.Title className="sr-only">Set your location</Dialog.Title>
        {customerLocation && (
          <>
            {tab === 0 && (
              <div className="p-6">
                <CountryFlag className="w-12 h-auto mx-auto" countryCode={customerLocation.country} />
                <div className="text-center text-lg mt-6">
                  Your location is set to <span className="font-medium">{toCountryName(customerLocation.country)}</span>
                </div>
                <ul className="list-disc ml-4 mt-6">
                  <li>
                    Shop in {customerLocaleFromIp?.currency.isoCode} {customerLocaleFromIp?.currency.symbol}
                  </li>
                  <li>Get shipping options for {toCountryName(customerLocation.country)}</li>
                </ul>
                <Button className="w-full mt-6" onClick={() => handleSubmit(customerLocation.country)}>
                  Continue
                </Button>
                {customerLocaleFromIp  && (
                   <Button
                   className="w-full mt-4"
                   variant="unstyled"
                   onClick={() => {
                     setTab(1);
                     setLocale(customerLocaleFromIp);
                   }}
                 >
                   Change country/region
                 </Button>
                )}
               
              </div>
            )}

            {tab === 1 && (
              <div className="p-6">
                <div className="text-lg">Change country/region</div>
                <ul className="mt-6">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="unstyled"
                        className={cn(
                          'flex w-full items-center space-x-2 rounded-[4px] px-3 py-2 mr-2.5 group',
                          'border border-border-neutral-grey-1100 bg-white',
                          'focus:outline-none',
                        )}
                      >
                        <CountryFlag className="h-4 w-auto" countryCode={locale!.isoCode} />
                        <span className="text-left flex-1">
                          {locale?.name} ({locale?.currency.isoCode} {locale?.currency.symbol})
                        </span>
                        <DownChevronIcon
                          className="group-data-[state=open]:rotate-180 transition"
                          width={16}
                          height={16}
                        />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="start" sideOffset={8} className='bg-white z-[1002] border border-black rounded-sm'>
                      {sortBy(availableCountries, 'name').map((country) => (
                        <DropdownMenuItem key={country.isoCode} className='hover:underline' onClick={() => setLocale(country)}>
                          {country.name} ({country.currency.isoCode} {country.currency.symbol})
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </ul>
                <Button className="w-full mt-4" onClick={() => handleSubmit(locale!.name)}>
                  Continue
                </Button>
                <Button className="w-full mt-4" variant="unstyled" onClick={() => setTab(0)}>
                  Cancel
                </Button>
              </div>
            )}
          </>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};

const CountryFlag = ({ countryCode, className }: { className?: string; countryCode: string }) => (
  <Image
    alt=""
    className={className}
    height={480}
    src={`https://cdn.shopify.com/static/images/flags/${countryCode.toLowerCase()}.svg`}
    width={640}
  />
);

function toCountryName(countryCode: string) {
  const displayName = new Intl.DisplayNames(['en'], { type: 'region' });
  return displayName.of(countryCode);
}

export default LocationDialog;
