import type { MenuLink } from '@ui/providers/static-content-provider';
import cn from '@ui/utils/cn';
import { Container } from '@ui/components/core/container';
import { groupBy } from 'lodash';
import Image from '@ui/components/core/image';
import Link from 'next/link';
import React from 'react';
import { parentCollectionSelectors } from '@ui/store/parentCollectionStore';
import { NavigationMenuContent, NavigationMenuItem, NavigationMenuLink, NavigationMenuTrigger } from '@ui/components/core/navigationMenu';

type MegaMenuPopoverProps = {
  menuLink: MenuLink;
  index: number;
  length: number;
}

const MegaMenuPopover:React.FC<MegaMenuPopoverProps> = ({ menuLink,index, length }) => {
  const { parentCollectionStore } = parentCollectionSelectors;
  const setParentCollection = parentCollectionStore.use.setParentCollection();

  const link = (
    <Link
      onClick={() => {
        setParentCollection(menuLink.label.toLowerCase());
      }}
      href={menuLink.href || '/'}
      className={cn(
        'w-full flex py-2 items-center text-center text-sm tracking-[0.015em] whitespace-nowrap',
        'hover:underline hover:underline-offset-[6px]',
        ['Sale', '80% OFF SALE'].includes(menuLink.label) && 'text-[#B40000]',
      )}
    >
      {menuLink.label.toUpperCase()}
    </Link>
  );

  if (!menuLink.children) {
    return (
      <NavigationMenuItem className="">
        <NavigationMenuTrigger className={cn("w-fit pl-4 pr-4",index===0 && "pl-0", index === length - 1 && "pr-0")} asChild>
          {link}
        </NavigationMenuTrigger>
      </NavigationMenuItem>
    );
  }

  const renderLinks = menuLink.children[0].children ? menuLink.children : [menuLink];

  return (
    <NavigationMenuItem className="">
      <NavigationMenuTrigger className={cn("w-fit pl-3 pr-3 xl:pl-5 xl:pr-5",index===0 && "pl-0 xl:pl-0", index === length - 1 && "pr-0 xl:pr-0")} asChild>
        {link}
      </NavigationMenuTrigger>
      <NavigationMenuContent className="w-[100vw] max-w-unset">
        <div className="w-[100vw] border-b border-black">
          <Container className="w-[100vw] pt-5 pb-8 max-w-unset">
            <div className={cn('grid gap-4', menuLink.label === 'Clothing' ? 'grid-cols-7' : 'grid-cols-6')}>
              {renderLinks.map((subLink) => (
                <ChildLinks
                  key={subLink.label}
                  links={subLink.children || []}
                  label={subLink.label === menuLink.label ? undefined : subLink.label}
                  parentLabel={menuLink.label}
                />
              ))}

              {menuLink.upsell && (
                <div className="col-span-2 col-end-7">
                  <div
                    className={cn('grid gap-8 pl-12', menuLink.upsell?.length === 1 ? 'grid-cols-1' : 'grid-cols-2')}
                  >
                    {menuLink.upsell.map(({ imageUrl, productUrl, productTitle }) => (
                      <NavigationMenuLink className="block" asChild key={imageUrl}>
                        <Link href={productUrl} className="block">
                          <div className={cn('relative', menuLink.upsell?.length === 1 ? 'pb-[66.66%]' : 'pb-[150%]')}>
                            <Image
                              src={imageUrl}
                              className="absolute w-full h-full object-cover"
                              alt={productTitle}
                              loading="eager"
                            />
                          </div>
                        </Link>
                      </NavigationMenuLink>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </Container>
        </div>
      </NavigationMenuContent>
    </NavigationMenuItem>
  );
};

type ChildLinksProp = {
  links: MenuLink[];
  label?: string;
  parentLabel: string;
};

const ChildLinks = ({ links, label, parentLabel }: ChildLinksProp) => {
  const { parentCollectionStore } = parentCollectionSelectors;
  const setParentCollection = parentCollectionStore.use.setParentCollection();

  return (
    <React.Fragment>
      {Object.values(groupBy(links, 'column')).map((groupedLinks, i) => (
        <div key={groupedLinks[0].label} className="flex flex-col gap-y-4">
          {label && <div className="text-[13px] font-bold uppercase">{i === 0 ? label : <span>&nbsp;</span>}</div>}

          {groupedLinks.map((childLink,index) => (
             <NavigationMenuLink asChild key={`${childLink.label}-${index}`}>
              <Link
                className="w-fit block hover:bg-[#F3C5D7] transition text-sm"
                href={childLink.href || '/'}
                onClick={() => setParentCollection(parentLabel.toLowerCase())}
              >
                {childLink.label}
              </Link>
            </NavigationMenuLink>
          ))}
        </div>
      ))}
    </React.Fragment>
  );
};

export default MegaMenuPopover;
