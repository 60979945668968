import React, { useState } from 'react';
import { CartLine } from '@client-shopify/gql/storefront/api/queries/GetCart';
import { CompactCloseIcon, DownChevronIcon } from '../../../core';
import { Money } from '@ui/components/shared';
import { Button } from "@ui/components/core/button"
import { WishlistProduct } from '@ui/types/contextObjects';
import useCartStore from '@ui/store/cartStore';
import Image from '@ui/components/core/image';
import cn from '@ui/utils/cn';
import { useGetCart } from '@ui/hooks/useCartQuery';
import { useCartCreation, useCartUpdate, useCartAdd } from '@ui/hooks/useCartMutations';
import { useAuthStore } from '@ui/store/authStore';
import { wishlistSelectors } from '@ui/store/wishlistStoreAsync';
import { getCustomerCountry } from '@ui/hooks/useCustomerCountry';
import Link from 'next/link';
import { DropdownMenu, DropdownMenuContent,DropdownMenuItem,DropdownMenuTrigger } from '@ui/components/core/dropdownMenu';

type WishlistProductCardProps = {
  product: WishlistProduct;
  onClose: () => void;
};

const WishlistProductCard = ({ product, onClose }: WishlistProductCardProps): React.ReactElement => {
  const { wishlistStore } = wishlistSelectors;
  const customerId = useAuthStore((state) => state.customerId);
  const [fadeOut, setFadeOut] = useState(false);
  const removeWishlistProduct = wishlistStore.use.removeWishlistProduct();
  const { cartLinesAddMutation, cartAddError, cartAddLoading } = useCartAdd();
  const cartID = useCartStore((state) => state?.cartId);
  const cartUpdatedKey = useCartStore((state) => state?.cartUpdatedKey);
  const setCartId = useCartStore((state) => state?.setCartId);
  const setCartUpdatedKey = useCartStore((state) => state?.setCartUpdatedKey);

  const { data } = useGetCart({
    refreshKey: cartUpdatedKey || '',
    cartId: cartID || '',
  });

  const { cartCreateError, cartCreateLoading, cartCreateMutation } = useCartCreation();

  const { cartLinesUpdateMutation, cartLineUpdateLoading } = useCartUpdate();
  const [cartLines, setCartLines] = React.useState<CartLine[] | [] | undefined>(undefined);

  const [selected, setSelected] = React.useState<{ id: string; sku: string; size: string } | undefined>(undefined);

  React.useEffect(() => {
    if (product.variantSizes.length === 1) {
      setSelected(product.variantSizes[0]);
    }
  }, [product.variantSizes]);

  React.useEffect(() => {
    if (data && data.cart && data.cart.cartLines) {
      setCartLines(data.cart.cartLines as CartLine[]);
    }
  }, [data]);

  const removeFromWishlist = (): void => {
    void removeWishlistProduct({
      customerId,
      productId: product.id,
    });
  };

  const addtoCart = (): void => {
    if (selected) {
      if (!cartLines) {
        cartCreateMutation({
          cartCreateInput: {
            buyerIdentity: {
              countryCode: getCustomerCountry(),
            },
            lines: [
              {
                merchandiseId: `gid://shopify/ProductVariant/${selected.id}`,
                quantity: 1,
              },
            ],
          },
        }).then((result) => {
          if (cartCreateError) {
            throw new Error(cartCreateError.message);
          }

          if (result?.cart && result?.cart.cartLines && result?.cart.cartLines.length > 0) {
            setCartId?.(result.cart.id);
            setCartUpdatedKey?.();

            removeFromWishlist();
          }
        });
      }

      if (
        cartLines &&
        cartLines.length > 0 &&
        cartLines.some((cartLineProduct) => cartLineProduct.variantId === `gid://shopify/ProductVariant/${selected.id}`)
      ) {
        const cartUpdateProduct = cartLines.find(
          (cartLineProduct) => cartLineProduct.variantId === `gid://shopify/ProductVariant/${selected.id}`,
        );

        if (cartUpdateProduct) {
          cartLinesUpdateMutation({
            cartId: cartID ?? '',
            cartUpdateInput: {
              quantity: cartUpdateProduct.quantity + 1,
              merchandiseId: `gid://shopify/ProductVariant/${selected.id}`,
              id: cartUpdateProduct.id,
            },
          }).then((result) => {
            if (cartCreateError) {
              throw new Error(cartCreateError.message);
            }

            if (result?.cart && result?.cart.cartLines && result?.cart.cartLines.length > 0) {
              setCartUpdatedKey?.();
              removeFromWishlist();
            }
          });
        }
      } else {
        cartLinesAddMutation({
          cartId: cartID ?? '',
          cartLinesAddInput: [
            {
              merchandiseId: `gid://shopify/ProductVariant/${selected.id}`,
              quantity: 1,
            },
          ],
        }).then((result) => {
          if (cartAddError) {
            throw new Error(cartAddError.message);
          }

          if (result?.cart && result?.cart.cartLines && result?.cart.cartLines.length > 0) {
            setCartUpdatedKey?.();
            removeFromWishlist();
          }
        });
      }
    }
  };

  return (
    <div
      style={{
        opacity: fadeOut ? 0 : 1,
      }}
      className="flex space-x-4 transition-all"
    >
      <Image className="self-start shrink-0" src={product.imageSrc} alt={product.imageAlt} height={132} width={88} />
      <div className="w-full flex flex-col justify-between space-y-1">
        <div>
          <div className="flex items-start space-x-2">
            <Link
              href={`/products/${product.handle}/`}
              className="font-bold text-sm uppercase flex-1"
              onClick={onClose}
            >
              {product.title}
            </Link>
            <Button
              variant="unstyled"
              className="p-2"
              onClick={() => {
                setFadeOut(true);
                removeFromWishlist();
              }}
            >
              <CompactCloseIcon height={10} width={10} />
            </Button>
          </div>
          {selected && <div className="text-[10px] lg:text-xs uppercase mt-1">Size: {selected.size}</div>}
        </div>
        <div className="flex items-end justify-between">
          {selected ? (
            <Button
              loading={cartCreateLoading || cartAddLoading || cartLineUpdateLoading}
              variant="primary"
              className="!text-[13px] !font-normal !h-8 !w-[7.75rem] !px-4 !uppercase"
              onClick={() => addtoCart()}
            >
              Move to bag
            </Button>
          ) : (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="unstyled"
                  className={cn(
                    'text-sm font-normal h-8 w-[7.75rem] px-4 group no-underline',
                    'flex items-center justify-between space-x-2',
                    'border border-neutral-grey-1100 rounded-[4px]',
                    'focus:outline-none',
                  )}
                >
                  <span>Select size</span>
                  <DownChevronIcon className="group-data-[state=open]:rotate-180 transition" width={16} height={16} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className='border border-black rounded-md z-[9999]' sideOffset={2}>
                {product.variantSizes?.map((variantSize) => (
                  <DropdownMenuItem
                    key={`${variantSize.id}-menu-item`}
                    onClick={() => {
                      variantSize.available && setSelected(variantSize);
                    }}
                    className={cn("hover:underline underline-offset-2 py-1",!variantSize.available && 'opacity-50 pointer-events-none')}
                  >
                    {variantSize.size}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
              
            </DropdownMenu>

 
          )}
          <div className="flex flex-row items-end gap-x-1">
            <div
              className={cn(
                parseFloat(product.msrp) > parseFloat(product.price)
                  ? 'text-red-1 text-xs lg:text-[13px] font-bold'
                  : 'text-xs lg:text-[13px] font-normal',
              )}
            >
              <Money value={product.price} currency={product.currency} />
            </div>
            {parseFloat(product.msrp) > parseFloat(product.price) && (
              <div className="text-xs lg:text-[13px] font-normal line-through opacity-65">
                <Money value={product.msrp} currency={product.currency} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishlistProductCard;
