import cn from '@ui/utils/cn';
import Money from '../../formatters/Money/Money';
import { isOnSale } from '@ui/helpers/productHelpers';

type ProductCardPriceProps = {
  className?: string;
  price: string;
  compareAtPrice?: string;
  currency?: string;
};

const ProductCardPrice = ({ className, price, compareAtPrice, currency }: ProductCardPriceProps) => {
  const onSale = isOnSale(price, compareAtPrice);

  return (
    <div className={cn('flex gap-1', className)}>
      <div className={cn('text-xs leading-[22px]', onSale && 'text-red-1')}>
        <Money value={parseFloat(price).toFixed(2)} currency={currency} />
      </div>
      {onSale && (
        <div className="text-xs leading-[22px] text-[#727272] line-through">
          <Money value={parseFloat(compareAtPrice!).toFixed(2)} currency={currency} />
        </div>
      )}
    </div>
  );
};

export default ProductCardPrice;
