import cn from '@ui/utils/cn';
import React from 'react';

export type InputProps = React.ComponentProps<'input'> & {
  invalid?: boolean;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, invalid, ...props }, ref) => {
  return (
    <input
      {...props}
      ref={ref}
      data-invalid={invalid || undefined}
      data-disabled={props.disabled || undefined}
      className={cn(
        'block w-full px-3 py-0 h-11 rounded-[4px] transition text-base tracking-[0.005em]',
        'bg-white border border-neutral-grey-1100 placeholder:text-[#727272]',
        'data-[invalid]:border-state-error-primary',
        'data-[disabled]:border-[#9E9E9E] data-[disabled]:text-[#9E9E9E]',
        'hover:border-[#424242]',
        'focus:border-[#212121] focus:outline-none',
        className,
      )}
    />
  );
});

Input.displayName = 'Input';

export default Input;
