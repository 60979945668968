import React, { useEffect } from 'react';
import Input, { InputProps } from './Input';
import { CountryCode, getCountryCallingCode, parsePhoneNumber } from 'libphonenumber-js/mobile';
import { useElementSize } from '@mantine/hooks';
import PhoneCountrySelect from './PhoneCountrySelect';
import cn from '@ui/utils/cn';

type PhoneInputProps = Omit<InputProps, 'ref' | 'onChange'> & {
  classNames?: Record<'root' | 'input' | 'button' | 'select', string>;
  invalid?: boolean;
  value?: string;
  defaultCountryCode: CountryCode;
  onChange?: (newValue: string) => void;
  disabled?: boolean;
};

const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(
  ({ value: valueProp, onChange, className, classNames, defaultCountryCode, ...props }, ref) => {
    const prevValue = React.useRef(safelyParsePhoneNumber(valueProp, defaultCountryCode));

    const [value, setValue] = React.useState(() => {
      const phoneNumber = safelyParsePhoneNumber(valueProp, defaultCountryCode);
      return phoneNumber?.nationalNumber?.toString() || '';
    });

    const [countryCode, setCountryCode] = React.useState(() => {
      const phoneNumber = safelyParsePhoneNumber(valueProp, defaultCountryCode);
      return phoneNumber?.country || defaultCountryCode;
    });

    const handleValueChange = (newValue: string) => {
      setValue(newValue);

      const newPhoneNumber = safelyParsePhoneNumber(newValue, countryCode);

      if (newPhoneNumber) {
        prevValue.current = newPhoneNumber;
        onChange?.(newPhoneNumber.number.toString());
      } else if (newValue === '') {
        onChange?.('');
      }
    };

    const handleCountryCodeChange = (newCountryCode: CountryCode) => {
      setCountryCode(newCountryCode);

      const newPhoneNumber = safelyParsePhoneNumber(value, newCountryCode);

      if (newPhoneNumber) {
        prevValue.current = newPhoneNumber;
        setValue(newPhoneNumber.nationalNumber.toString());
        onChange?.(newPhoneNumber.number.toString());
      } else {
        setValue('');
        onChange?.('');
      }
    };

    useEffect(() => {
      const newPhoneNumber = safelyParsePhoneNumber(valueProp, countryCode);

      if (newPhoneNumber) {
        setValue(newPhoneNumber.nationalNumber.toString());
      } else if (valueProp === '') {
        setValue('');
      }

      if (newPhoneNumber?.country) {
        setCountryCode(newPhoneNumber.country);
      }
    }, [valueProp, countryCode]);

    const { ref: adornmentRef, width: adornmentWidth } = useElementSize();

    return (
      <div className={cn('flex gap-3', classNames?.root, className)}>
        <PhoneCountrySelect className={cn('px-2',classNames?.select)} disabled={props.disabled} value={countryCode} onChange={handleCountryCodeChange} />
        <div className="relative flex items-center w-full">
          <Input
            {...props}
            onChange={(e) => handleValueChange(e.target.value)}
            ref={ref}
            disabled={props.disabled}
            style={{ paddingLeft: `${adornmentWidth}px` }}
            value={value}
          />
          <div ref={adornmentRef} className="absolute pointer-events-none text-base text-[#727272] tracking-[0.005em]">
            <span className="pl-3 pr-2">(+{getCountryCallingCode(countryCode)})</span>
          </div>
        </div>
      </div>
    );
  },
);

PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;

function safelyParsePhoneNumber(text: string = '', defaultCountry?: CountryCode) {
  try {
    return parsePhoneNumber(text, defaultCountry);
  } catch {
    return undefined;
  }
}
