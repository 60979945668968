import React, { forwardRef } from "react";
import cn from "@ui/utils/cn";

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLElement>;

const Container = forwardRef<HTMLElement, ContainerProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <section
        ref={ref}
        className={cn("w-full max-w-[112.5rem] px-3 md:px-8 mx-auto", className)}
        {...props}
      >
        {children}
      </section>
    );
  }
);

Container.displayName = "Container";

export { Container };