import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Cart } from '@client-shopify/gql/storefront/api/queries/GetCart';
import { CustomerAccessTokenDelete } from '@client-shopify/gql/storefront/api/mutations';
import { v4 as uuidv4 } from 'uuid';
import { setCookie } from '@ui/hooks/useCookie';
import { useAuthStore } from '@ui/store/authStore';
import { wishlistSelectors } from '@ui/store/wishlistStoreAsync';
import useYotpoDataLayer from '@ui/hooks/useYotpoDataLayer';

import { Banner } from '@ui/components/features';
import {
  CartDrawer,
  MegaMenuDrawer,
  NotificationBadge,
  SearchModal,
  WishlistDrawer,
  MegaMenuPopover,
} from '@ui/components/shared';
import { Button } from '@ui/components/core/button';
import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from '@ui/components/core/popover';
import {
  AccountIcon,
  WishlistIcon,
  BagIcon,
  CompactSearchIcon,
  Logo,
  LogoCircleIcon,
  MenuIcon,
  SearchIcon,
  CompactCloseIcon,
  AccountLogOutIcon,
} from '@ui/components/core';
import { HeaderProps } from '@ui/types/pages';
import useCartStore from '@ui/store/cartStore';
import Link from 'next/link';
import { sumBy, throttle } from 'lodash';
import useModalStore from '@ui/store/modalStore';
import cn from '@ui/utils/cn';
import { useGetCart } from '@ui/hooks/useCartQuery';
import { isWishlistFrontEnd } from '@ui/helpers/isWishlistFrontEnd';
import type { WishlistFrontEnd } from '@ui/types/contextObjects';
import { useStaticContent } from '@ui/providers/static-content-provider';
import { AccountNavLinks } from '@ui/types/mappings/AccountNavMappings.type';
import { useElementSize } from '@mantine/hooks';
import { Container } from '@ui/components/core/container';
import { NavigationMenu, NavigationMenuList } from '@ui/components/core/navigationMenu';

const hasWishlistItems = (wishlist: {} | WishlistFrontEnd) => {
  if (isWishlistFrontEnd(wishlist)) {
    return !!Object.keys(wishlist.products).length;
  }

  return false;
};

const Header = ({ title, layout, backIcon }: HeaderProps): React.ReactElement => {
  const { yotpoCustomerIdentifcation } = useYotpoDataLayer();
  const router = useRouter();
  const { token, login, logout, customer, isLoggedIn: loggedIn } = useAuthStore();
  const customerId = useAuthStore.getState().customerId;
  const { wishlistStore } = wishlistSelectors;
  const cartId = useCartStore((state) => state?.cartId);
  const setCartId = useCartStore((state) => state?.setCartId);
  const cartUpdatedKey = useCartStore((state) => state?.cartUpdatedKey);
  const setCartUpdatedKey = useCartStore((state) => state?.setCartUpdatedKey);
  const getWishlist = wishlistStore.use.getWishlist();
  const setWishlistSession = wishlistStore.use.setWishlistSession();
  const wishlist = wishlistStore.use.wishlist();
  const openedModal = useModalStore((state) => state.openedModal);
  const openModal = useModalStore((state) => state.openModal);
  const closeModal = useModalStore((state) => state.closeModal);
  const searchFormRef = useRef<HTMLInputElement>();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [popoverOpened, setPopoverOpened] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const searchTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (token) {
      login(token as string);
    }
  }, [token, login]);

  useEffect(() => {
    if (customer?.customer?.email) {
      yotpoCustomerIdentifcation(customer?.customer?.email);
    }
  }, [customer?.customer?.email, yotpoCustomerIdentifcation]);

  useEffect(() => {
    (async () => {
      await getWishlist(customerId);
    })();
  }, [customerId, getWishlist]);

  const { data: getCartQueryResults } = useGetCart({
    refreshKey: cartUpdatedKey || '',
    cartId: cartId || '',
  });

  useEffect(() => {
    if (!router.query['cart-abandonment']) return;
    if (!cartId) {
      setCartId?.(router.query['cart-abandonment'] as string);
      setCartUpdatedKey?.();
    }
  }, [router.query, getCartQueryResults, cartId, openModal, setCartId, setCartUpdatedKey]);

  const desktopMenuLinkTree = useStaticContent('Menu.DesktopMenuLinkTree');
  const [cartAnimate, setCartAnimate] = useState<boolean>(false);
  const [cartItemCount, setCartItemCount] = useState(0);
  function storePathValues() {
    const storage = globalThis.sessionStorage;

    if (!storage) return;

    const prevPath = storage.getItem('currentPath');

    storage.setItem('previousPath', prevPath || globalThis.location.pathname);
    storage.setItem('currentPath', globalThis.location.pathname);
  }

  function handleBackPress() {
    const storage = globalThis.sessionStorage;

    const previousPath = storage.getItem('previousPath') || '';
    const currentPath = storage.getItem('currentPath') || '';
    const authPaths = ['/account/login', '/account/signup/'];

    if (previousPath === currentPath) {
      router.push('/');
    } else if (authPaths.includes(previousPath)) {
      router.push('/');
    } else {
      router.back();
    }
  }

  useEffect(() => {
    storePathValues();
    setCookie('pageLoadId', uuidv4());
  }, [router.asPath]);

  useEffect(() => {
    const cartItemsLength: number = getCartQueryResults?.cart?.cartLines?.length || 0;
    const dynamicCartItemCount = cartItemCount;
    const newCartItemCount = sumBy(Object.values(dynamicCartItemCount), 'quantity');
    setCartAnimate(dynamicCartItemCount < newCartItemCount);
    setCartItemCount(cartItemsLength);
  }, [cartUpdatedKey, cartItemCount, getCartQueryResults?.cart?.cartLines?.length]);

  // The header shrinks at 94px down the page, however the shrinkking actually changes the scrollTop as the USP bar is hidden entirely. The TOLERANCE value is to provide a buffer so that the header doesn't continually flicker between hidden and shown states at a certain scroll point.
  useEffect(() => {
    const TOLERANCE = 32;
    const onScroll = throttle(() => {
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      const isShrunk = document.body.scrollTop > 94 + TOLERANCE || document.documentElement.scrollTop > 94 + TOLERANCE;

      if (isShrunk) {
        document.documentElement.setAttribute('data-shrunk', 'true');
      } else if (scrollTop < 94 - TOLERANCE) {
        document.documentElement.removeAttribute('data-shrunk');
      }
    }, 100);

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', closeModal);
    return () => router.events.off('routeChangeStart', closeModal);
  }, [router.events, closeModal]);

  const onSearchClick = () => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    const fakeInput = document.createElement('input');
    fakeInput.setAttribute('type', 'text');
    fakeInput.style.position = 'absolute';
    fakeInput.style.opacity = '0';
    fakeInput.style.height = '0';
    fakeInput.style.fontSize = '16px';
    document.body.prepend(fakeInput);
    fakeInput.focus();

    searchTimeoutRef.current = setTimeout(() => {
      const searchForm: HTMLInputElement | null = document.querySelector('[data-searchform]');
      if (!searchForm) return;
      fakeInput.blur();
      searchForm.focus();
      searchForm.click();
      fakeInput.remove();
    }, 1000);
  };

  const { ref, height } = useElementSize();

  useEffect(() => {
    document.documentElement.style.setProperty('--header-height', `${height + 1}px`);
  }, [height]);

  useEffect(() => {
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, []);
  const wishlistCartButtons = (
    <>
      <Button
        type="button"
        className="overflow-visible lg:size-8"
        aria-label="Wishlist Button"
        variant="unstyled"
        onClick={() => openModal('wishlist')}
        data-modal-toggle
      >
        <NotificationBadge
          className="size-[0.8rem] mt-[0.4rem] mr-[0.25rem]"
          show={hasWishlistItems(wishlist)}
          animate={false}
        >
          <WishlistIcon width={28} height={28} fill={hasWishlistItems(wishlist) ? '#F7D6E3' : 'white'} />
        </NotificationBadge>
      </Button>
      <Button
        type="button"
        className="overflow-visible lg:size-8"
        aria-label="Cart Drawer Button"
        variant="unstyled"
        onClick={() => openModal('cart')}
        data-modal-toggle
      >
        <NotificationBadge
          className="size-[1.3rem] mt-[0.25rem] mr-[0.25rem]"
          label={cartItemCount.toString()}
          show={cartItemCount > 0}
          animate={cartAnimate}
        >
          <BagIcon
            width={28}
            height={28}
            className={cn(
              'transition-all ease-in duration-200',
              cartItemCount > 0 ? 'fill-pink-11' : 'fill-white',
              cartAnimate && 'animate-grow',
            )}
          />
        </NotificationBadge>
      </Button>
    </>
  );

  return (
    <>
      <header
        ref={ref}
        className={cn(
          'sticky top-0 bg-white border-b-[0.6px] border-black z-[1001] delay-75',
          'lg:shrunk:translate-y-[calc(var(--banner-height)-var(--menu-height))]  shrunk:translate-y-[calc(var(--banner-height)-var(--menu-height))] shrunk:duration-500',
          'transition duration-700 ease-out',
        )}
      >
        <div
          className={cn(
            'translate-y-0 opacity-100 delay-75',
            'shrunk:-translate-y-full shrunk:opacity-0 shrunk:duration-500',
            'transition duration-500 ease-out',
          )}
          data-date-range="date-range"
          data-banner
        >
          <Banner />
        </div>

        <div className="hidden lg:block">
          <Container className='md:px-0 relative'>
            <PolymorphicLogoComponent urlPath={router.pathname}>
              <div className="flex justify-center mt-[18px]translate-y-0 opacity-100 transition duration-700 ease-out shrunk:mt-[0px]">
                <Logo height={50} width={160} />
              </div>
              <div
                className="flex items-center justify-between 
                w-[9.25rem] transition-[width] 
                duration-700 [&_svg:hover]:scale-[1.1] 
                absolute top-[18px] right-8"
              >
                <Button
                  type="button"
                  variant="unstyled"
                  onClick={() => {
                    openModal('search');
                    onSearchClick();
                  }}
                  className="no-underline size-8"
                  aria-label="Search Button"
                  data-modal-toggle
                >
                  <SearchIcon height={28} width={28} />
                </Button>
                <>
                  <Popover open={popoverOpened}>
                    <PopoverTrigger asChild>
                      <Button
                        variant="unstyled"
                        onClick={() => setPopoverOpened(true)}
                        onMouseEnter={() => {
                          clearTimeout(timeoutRef.current);
                          timeoutRef.current = setTimeout(() => setPopoverOpened(true), 300);
                        }}
                        onMouseLeave={() => {
                          clearTimeout(timeoutRef.current);
                          timeoutRef.current = setTimeout(() => setPopoverOpened(false), 200);
                        }}
                        aria-label="Account Button"
                        className="size-8 outline-none"
                      >
                        <AccountIcon
                          className={cn(!loggedIn ? 'fill-white' : 'fill-pink-11', 'text-black')}
                          height={28}
                          width={28}
                        />
                      </Button>
                    </PopoverTrigger>

                    <PopoverContent
                      sideOffset={0}
                      align="end"
                      className="z-[100000] p-2 uppercase border-[0.6px] border-black"
                      onMouseEnter={() => {
                        clearTimeout(timeoutRef.current);
                        timeoutRef.current = setTimeout(() => setPopoverOpened(true), 300);
                      }}
                      onMouseLeave={() => {
                        clearTimeout(timeoutRef.current);
                        timeoutRef.current = setTimeout(() => setPopoverOpened(false), 200);
                      }}
                    >
                      {loggedIn && customer && customer.customer ? (
                        <div className="flex flex-col gap-y-2">
                          <div className="border-[0.6px] border-black rounded-[0.25rem] bg-pink-2 text-base py-2 px-4 flex justify-start items-center gap-x-4">
                            <LogoCircleIcon height={38} width={38} />
                            <div className="flex flex-col">Hello, {customer.customer?.firstName}</div>
                          </div>
                          <div className="flex flex-col gap-y-2 pb-2 border-b-[0.6px] border-neutral-grey-300 text-sm">
                            {AccountNavLinks.header.loggedIn.map(({ IconComponent, href, label }) => (
                              <Link
                                href={href}
                                key={`header-${href}`}
                                className="flex justify-start items-center gap-x-2 py-[0.62rem] px-[0.75rem] text-sm hover:bg-pink-4 rounded-[0.25rem]"
                                onClick={() => setPopoverOpened(false)}
                              >
                                <IconComponent />
                                <span>{label}</span>
                              </Link>
                            ))}
                          </div>
                          <div>
                            <Button
                              className="uppercase text-sm text-left no-underline w-full border-none flex justify-start items-center gap-x-2 py-[0.62rem] px-[0.75rem] hover:bg-pink-4 rounded-[0.25rem]"
                              variant="unstyled"
                              disabled={isLoggingOut}
                              loading={isLoggingOut}
                              aria-label="Log Out Button"
                              onClick={async () => {
                                setIsLoggingOut(true);

                                CustomerAccessTokenDelete({
                                  customerAccessToken: token ?? '',
                                });

                                logout();
                                setWishlistSession();
                                setPopoverOpened(false);
                                router.push('/');
                              }}
                            >
                              <AccountLogOutIcon height={24} width={24} />
                              <span>LOG OUT</span>
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-col gap-y-2">
                          <div className="flex flex-col gap-y-2 pb-2 border-b-[0.6px] border-neutral-grey-300">
                            {AccountNavLinks.header.loggedOut.top.map(({ IconComponent, href, label }) => (
                              <Link
                                href={href}
                                key={`header-${href}`}
                                className="flex justify-start items-center gap-x-2 py-[0.62rem] px-[0.75rem] text-sm hover:bg-pink-4 rounded-[0.25rem]"
                                onClick={() => setPopoverOpened(false)}
                              >
                                <IconComponent />
                                <span>{label}</span>
                              </Link>
                            ))}
                          </div>
                          <div>
                            {AccountNavLinks.header.loggedOut.bottom.map(({ IconComponent, href, label }) => (
                              <Link
                                href={href}
                                key={`header-${href}`}
                                className="flex justify-start items-center gap-x-2 py-[0.62rem] px-[0.75rem] text-sm hover:bg-pink-4 rounded-[0.25rem]"
                                onClick={() => setPopoverOpened(false)}
                              >
                                <IconComponent />
                                <span>{label}</span>
                              </Link>
                            ))}
                          </div>
                        </div>
                      )}
                      <PopoverArrow />
                    </PopoverContent>
                  </Popover>
                </>
                {wishlistCartButtons}
              </div>
            </PolymorphicLogoComponent>
          </Container>

          <div className='relative'>
            <NavigationMenu className="static w-full [&>div]:w-full mt-4 max-w-[112.5rem] mx-auto px-8">
              <NavigationMenuList className="w-full flex-1">
                {desktopMenuLinkTree?.map((menuLink,index) => <MegaMenuPopover key={menuLink.label} menuLink={menuLink} length={desktopMenuLinkTree.length} index={index}/>)}
              </NavigationMenuList>
            </NavigationMenu>
          </div>
        </div>

        <Container className="lg:hidden">
          {layout === 'with-back-button-and-title' ? (
            <div className="flex items-end justify-center pt-4 pb-3 h-[60px]">
              <Button
                className={cn('absolute left-2', backIcon ? 'translate-y-[3px]' : 'translate-y-2')}
                variant="unstyled"
                aria-label="Back Button"
                onClick={handleBackPress}
              >
                {backIcon || <CompactCloseIcon height={14} width={14} />}
              </Button>
              <h1 className="font-bold text-sm uppercase">{title}</h1>
            </div>
          ) : (
            <>
              <div className="flex items-end justify-between pt-4 pb-3">
                <div className="flex items-end space-x-2 translate-y-1">
                  {layout === 'with-back-button' ? (
                    <Button type="button" variant="unstyled" aria-label="Back Button" onClick={handleBackPress}>
                      <CompactCloseIcon height={14} width={14} />
                    </Button>
                  ) : (
                    <Button type="button" variant="unstyled" onClick={() => openModal('megamenu')} data-modal-toggle>
                      <MenuIcon height={28} width={28} />
                    </Button>
                  )}
                  <Button
                    variant="unstyled"
                    onClick={() => {
                      openModal('search');
                      onSearchClick();
                    }}
                    aria-label="Search Button"
                    data-modal-toggle
                    className={cn(
                      !router.asPath.includes('/products/') &&
                        'opacity-0 shrunk:opacity-100 transition duration-500 ease-out',
                    )}
                  >
                    <SearchIcon height={24} width={24} />
                  </Button>
                </div>
                <PolymorphicLogoComponent urlPath={router.pathname}>
                  <Logo height={32} width={125.83} />
                </PolymorphicLogoComponent>
                <div className="flex items-end space-x-2 translate-y-1">{wishlistCartButtons}</div>
              </div>
              {!router.asPath.includes('/products/') && (
                <div
                  className={cn(
                    openedModal === 'search' ? 'opacity-0' : 'opacity-100',
                    'pb-[0.63rem] delay-75 block',
                    'shrunk:-translate-y-full shrunk:opacity-0 shrunk:duration-500 shrunk:hidden shrunk:pb-0',
                    'transition duration-700 ease-out overflow-hidden',
                  )}
                  style={{ transitionDelay: 'allow-discrete' }}
                  data-false-search-input
                >
                  <Button
                    variant="unstyled"
                    className="block border-[0.6px] border-black rounded-[6.25rem] bg-[#FAEAF0] overflow-hidden w-full no-underline"
                    aria-label="Search Button"
                    onClick={() => {
                      openModal('search');
                      onSearchClick();
                    }}
                  >
                    <div className="relative flex items-center">
                      <div className="absolute left-[0.48rem] pointer-events-none flex items-center justify-center rounded-full w-5 h-5">
                        <CompactSearchIcon className="w-4 h-4 text-neutral-grey-1100" />
                      </div>
                      <div className="bg-transparent w-full h-8 text-sm tracking-[0.03em] pl-9 flex items-center text-left focus:outline-none text-neutral-grey-1100">
                        Search
                      </div>
                    </div>
                  </Button>
                </div>
              )}
            </>
          )}
        </Container>
      </header>
      <MegaMenuDrawer isGuest={!loggedIn} opened={openedModal === 'megamenu'} onClose={closeModal} />
      <SearchModal opened={openedModal === 'search'} onClose={closeModal} ref={searchFormRef} />
      <WishlistDrawer opened={openedModal === 'wishlist'} onClose={closeModal} />
      <CartDrawer
        cart={getCartQueryResults?.cart as Cart}
        cartItemCount={cartItemCount}
        opened={openedModal === 'cart'}
        onClose={closeModal}
      />
    </>
  );
};

const PolymorphicLogoComponent = ({ urlPath, children }: { urlPath: string; children: ReactNode }) => {
  if (urlPath === '/') {
    return <h1>{children}</h1>;
  }
  return <>{children}</>;
};

export default Header;
