import { WishlistIcon } from "@ui/components/core";
import { Button } from "@ui/components/core/button";

type WishlistButtonProps = {
  className?: string;
  wishlistItem: boolean;
  wishlistAnimate: boolean;
  loading?: boolean;
  addToWishList: () => void;
  removeFromWishlist: () => void;
}


const WishlistButton:React.FC<WishlistButtonProps> = ({
  className,
  wishlistItem,
  wishlistAnimate,
  loading,
  addToWishList,
  removeFromWishlist
})=>{
  return (
    <Button
      variant="unstyled"
      className={className}
      onClick={() => {
        if (wishlistItem) {
          removeFromWishlist();
        } else {
          addToWishList();
        }
      }}
    >
      <WishlistIcon
        height={10}
        width={10}
        viewBox="0 0 25 25"
        className={`absolute transition-all duration-[1.02s] ease-in fill-pink-11 ${
          wishlistAnimate ? 'animate-[floatRight_2s_ease-in-out_0.25s]' : 'hidden'
        }`}
      />
      <WishlistIcon
        height={12}
        width={12}
        viewBox="0 0 25 25"
        className={`absolute transition-all duration-[1.02s] ease-in fill-pink-11 ${
          wishlistAnimate ? 'animate-[floatLeft_2s_ease-in-out_0.5s]' : 'hidden'
        }`}
      />
      <WishlistIcon
        height={14}
        width={14}
        viewBox="0 0 25 25"
        className={`absolute transition-all duration-[1.02s] ease-in fill-pink-11 ${
          wishlistAnimate ? 'animate-[floatRight_2s_ease-in-out_0.75s]' : 'hidden'
        }`}
      />
      <WishlistIcon
        height={16}
        width={16}
        viewBox="0 0 25 25"
        className={`absolute transition-all duration-[1.02s] ease-in fill-pink-11 ${
          wishlistAnimate ? 'animate-[floatLeft_2s_ease-in-out_0.75s]' : 'hidden'
        }`}
      />
      <WishlistIcon
        height={28}
        width={28}
        className={`z-[1] ${
          wishlistAnimate && !wishlistItem ? 'fill-pink-11' :
          wishlistAnimate && wishlistItem ? 'fill-pink-11' :
          !wishlistAnimate && wishlistItem ? 'fill-pink-11' :
          loading ? 'fill-transparent md:hover:fill-transparent'
          : 'fill-transparent md:hover:fill-pink-11'} ${
          wishlistAnimate ? 'animate-[heart-grow_0.5s_ease-in-out]' : ''
        }`}
      />

      
    </Button>
  )
}

export default WishlistButton;