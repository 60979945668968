import { type Region } from '@ui/providers/static-content-provider';

export const regionData: Array<Region> = [
  { value: 'US', label: 'United States' },
  { value: 'CA', label: 'Canada' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'APAC', label: 'Asia Pacific' },
  { value: 'AU', label: 'Australia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'OTHER', label: 'All Other Countries' },
];

export const auBody = (
  <div className="flex flex-col gap-3 items-center text-center">
    <p className="text-lg font-bold tracking-wider uppercase">AUSTRALIA</p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      Please shop on our $AUD website for shipping to Australia. Access the shipping page{' '}
      <a href="https://www.hellomolly.com.au/pages/shipping" className="underline">
        HERE
      </a>
    </p>
  </div>
);

export const usBody = (
  <div className="flex flex-col gap-3 items-center text-center">
    <p className="text-lg font-bold tracking-wider uppercase">NEXT DAY DELIVERY AVAILABLE*! AMAZING!</p>
    <p className="text-xs md:text-sm tracking-wider leading-6">We offer 3 shipping options within the USA.</p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      Next Day and 2-Day delivery (from the date of dispatch) is available if you need your order ASAP!
    </p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      If you&apos;re happy to wait a little longer, our Standard delivery option takes up to 6 business days. Please
      note this is not a signature required service and it will be left if deemed safe by the courier.
    </p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      Orders received before 2pm Pacific/California time on a business day will be shipped the same day from our Los
      Angeles warehouse, otherwise they are shipped the next business day.
    </p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      Our express shipping options are not available for PO Boxes.
    </p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      Delivery times are estimates only and may be subject to delays out of our control. See chart below for shipping
      prices.
    </p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      *Next Day delivery is not available for Alaska or Hawaii.
    </p>
  </div>
);

export const nzBody = (
  <div className="flex flex-col gap-3 items-center text-center">
    <p className="text-lg font-bold tracking-wider uppercase">NEW ZEALAND</p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      Please shop on our $NZD website for shipping to New Zealand. Access the shipping page{' '}
      <a href="https://www.hellomolly.co.nz/pages/shipping" className="underline">
        HERE
      </a>
    </p>
  </div>
);

export const caBody = (
  <div className="flex flex-col gap-3 items-center text-center">
    <p className="text-lg font-bold tracking-wider uppercase">GET IT IN 2 DAYS!</p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      We offer an Express shipping option to Canada which takes 1-2 business days for delivery.
    </p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      If you&apos;re happy to wait a little longer, our Standard shipping option takes up to 15 business days.
    </p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      Delivery times are estimates only and may be subject to delays out of our control. See chart below for shipping
      prices.
    </p>
  </div>
);

export const gbBody = (
  <div className="flex flex-col gap-3 items-center text-center">
    <p className="text-lg font-bold tracking-wider uppercase">GET IT IN 3 DAYS!</p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      We offer an Express shipping option to the United Kingdom which takes 2-3 business days for delivery.
    </p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      If you&apos;re happy to wait a little longer, our Standard shipping option takes up to 15 business days.
    </p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      Delivery times are estimates only and may be subject to delays out of our control. See chart below for shipping
      prices.
    </p>
  </div>
);

export const apacBody = (
  <div className="flex flex-col gap-3 items-center text-center">
    <p className="text-lg font-bold tracking-wider uppercase">GET IT IN 6 DAYS!</p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      For orders going to the Asia Pacific region, you can expect delivery within 6 business days!
    </p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      Delivery times are estimates only and may be subject to delays out of our control. See chart below for shipping
      prices.
    </p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      If you don&apos;t see your country listed, prices and more specific transit times will be shown at checkout.
    </p>
  </div>
);

export const otherBody = (
  <div className="flex flex-col gap-3 items-center text-center">
    <p className="text-lg font-bold tracking-wider uppercase">DELIVERY TIMES VARY</p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      Hello Molly ships worldwide! Orders going to the Rest Of The World should be with you within 2 weeks of dispatch.
      However we do ask that you allow up to a month for delivery in case of unforeseen delays. Thank you for your
      understanding.
    </p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      Delivery times are estimates only and may be subject to delays out of our control. See chart below for shipping
      prices.
    </p>
    <p className="text-xs md:text-sm tracking-wider leading-6">
      If you don&apos;t see your country listed, prices and more specific transit times will be shown at checkout.
    </p>
  </div>
);