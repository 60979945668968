import Sheet from '@ui/components/core/sheet';
import { MetricSelector } from '@ui/components/shared';
import env from '@ui/env';
import { Product } from '@ui/types/models/Product';
import cn from '@ui/utils/cn';
import React from 'react';
import { useInView } from 'react-intersection-observer';

const AuClothingSizes = [
  ['SIZE', 'CHEST', 'LENGTH'],
  ['S', '36cm - 48cm', '34cm - 36cm'],
  ['M', '52cm - 60cm', '36cm - 40cm'],
  ['L', '64cm - 70cm', '47cm - 50cm'],
];

const UsClothingSizes = [
  ['SIZE', 'CHEST', 'LENGTH'],
  ['S', '14in - 19in', '13in - 14in'],
  ['M', '20in - 24in', '14in - 16in'],
  ['L', '25in - 26in', '16in - 20in'],
];

const Sizes: Record<string, string[][]> = {
  'clothes.cm': AuClothingSizes.slice(1),
  'clothes.in': UsClothingSizes.slice(1),
};

const Measurements: Record<string, string[]> = {
  'clothes.cm': AuClothingSizes[0],
  'clothes.in': UsClothingSizes[0],
};

const DEFAULT_METRIC = env.NEXT_PUBLIC_REGION === 'US' ? 'in' : 'cm';

type Props = {
  product: Product;
  open: boolean;
  onClose: (open: boolean) => void;
};

type ContextValue = {
  product: Product;
  metric: string;
  setMetric: (metric: string) => void;
};

const Context = React.createContext<ContextValue>({} as ContextValue);

export default function PetSizeGuideSheet({ product, open, onClose }: Props) {
  const [metric, setMetric] = React.useState(DEFAULT_METRIC);

  React.useEffect(() => {
    if (open) return;
    setMetric(DEFAULT_METRIC);
  }, [open]);

  return (
    <Context.Provider
      value={{
        product,
        metric,
        setMetric,
      }}
    >
      <Sheet.Root open={open} onOpenChange={onClose}>
        <Sheet.Content
          side="dynamic"
          withCloseButton={false}
          className="isolate p-[--gutter] pt-0 [--gutter:1rem] !max-h-[37.5rem] md:[--gutter:2rem] md:!max-h-none"
        >
          <StickyHeader />
          <div className="mt-4 flex items-center justify-between">
            <div className="uppercase font-semibold tracking-[0.004em]">Pet Clothing</div>
            <MetricSelector value={metric} onChange={setMetric} />
          </div>
          <div className="mt-4">
            <SizeTable />
            <Disclaimer />
          </div>
        </Sheet.Content>
      </Sheet.Root>
    </Context.Provider>
  );
}

function StickyHeader() {
  const { ref, entry } = useInView();
  const [sticky, setSticky] = React.useState(false);

  React.useEffect(() => {
    entry && setSticky(!entry.isIntersecting);
  }, [entry]);

  return (
    <>
      <div ref={ref} />
      <div
        className={cn(
          'sticky top-0 z-10 bg-white py-4 md:pt-6 flex items-center justify-between',
          sticky ? '-mx-[--gutter] px-[--gutter] shadow-[0px_8px_8px_0px_#0000001A]' : 'border-b border-neutral-grey-300',
        )}
      >
        <Sheet.Title>Size Guide</Sheet.Title>
        <Sheet.Description className="sr-only">
          Hello Molly uses standard Australian sizes. Please refer to the conversion table below as a guide.
        </Sheet.Description>
        <Sheet.Close />
      </div>
    </>
  );
}

function StyledTable(props: React.HTMLAttributes<HTMLTableElement>) {
  return (
    <div className="overflow-auto">
      <table
        {...props}
        className={cn(
          'w-full',
          '[&_th]:text-sm [&_th]:text-center [&_th]:px-4 [&_th]:py-3 [&_th]:border [&_th]:border-[#E0E0E0] [&_th]:tracking-[0.004em] [&_th]:bg-pink-2 [&_th]:font-bold [&_th]:uppercase',
          '[&_td]:text-sm [&_td]:text-center [&_td]:px-4 [&_td]:py-3 [&_td]:border [&_td]:border-[#E0E0E0] [&_td]:tracking-[0.004em]',
          '[&_td:first-child]:font-semibold [&_td:first-child]:bg-pink-1 [&_td:first-child]:whitespace-nowrap',
        )}
      />
    </div>
  );
}

function SizeTable() {
  const { product, metric } = React.useContext(Context);

  const key = `${product.productType.toLowerCase()}.${metric}`;

  return (
    <StyledTable>
      <thead>
        <tr>
          {Measurements[key].map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Sizes[key].map((row) => (
          <tr key={row[0]}>
            {row.map((col) => (
              <td key={col}>{col}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
}

function Disclaimer() {
  return (
    <p className="text-xs tracking-[0.004em] leading-relaxed mt-4">
      <b>Small:</b> Ideal for breeds such as: Small Cavoodle, Jack Russell, Pug, Maltese, Shih Tzu, Pomeranian. <br />
      <b>Medium:</b> Ideal  for breeds such as: Cavoodle, West Highland Terrier, Scottish Terrier, Fox Terrier, Boston Terrier, French Bulldog. <br />
      <b>Large:</b> Ideal for breeds such as: Groodle, Kelpie, Border Collie, Bulldog, Bull Terrier.
    </p>
  );
}
