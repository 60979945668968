import React from 'react';
import cn from '@ui/utils/cn';
import  { Select as BaseSelect, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@ui/components/core/select';

export type SelectProps = {
  className?:string
  onChange: (value: string) => void;
  value: string | undefined;
  placeholder?: string;
  data: Array<{ value: string; label: string }>;
  invalid?: boolean;
  disabled?: boolean;
  onBlur?: () => void;
  id?: string;
  name?: string;
  formInput?: boolean;
  required?: boolean;
  flagSelect?: boolean;
  classNames?:{
    item?: string;
    content?: string;
  }
};

const Select = React.forwardRef<HTMLInputElement, SelectProps>(({ 
  name,
  value, 
  id, 
  className, 
  data, 
  placeholder = "Select an Option", 
  required,
  invalid, 
  disabled, 
  formInput, 
  onBlur, 
  onChange,
  flagSelect,
  classNames
}, ref) => {
  return (
    <div ref={ref}>
      <BaseSelect value={value} onValueChange={onChange}>
        <SelectTrigger
          className={cn(
            'flex items-center justify-between rounded-1 border border-neutral-grey-1100 text-base bg-white px-4 py-2 text-gray-700 shadow-sm focus:border-neutral-grey-1100 focus:outline-0 focus:ring-0',
            invalid && 'border-red-600 focus:border-red-600 focus:ring-red-600',
            flagSelect && 'gap-2',
            className
          )}
        >
          {flagSelect ? (
            <SelectValue placeholder={placeholder}>
              <p className='text-xl'>{data.find(item => item.value === value)?.label.split(' ')[0] || '🚫'}</p>
            </SelectValue>
          ): (<SelectValue placeholder={placeholder}/>)}
        </SelectTrigger>
        <SelectContent className={cn('mt-1 z-[1003]',classNames?.content)}>
          {data.map((item) => (
            <SelectItem
              key={item.value}
              value={item.value}
              className={cn(
                'cursor-pointer rounded-1 px-4 py-2 text-gray-700 text-sm hover:bg-gray-100 focus:bg-gray-100',
                disabled && 'opacity-50 cursor-not-allowed',
                classNames?.item
              )}
            >
              {item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </BaseSelect>
      {formInput && (
        <input
          required={required}
          type="hidden"
          name={name}
          id={id}
          value={value}
          onBlur={onBlur}
        />
      )}
    </div>
  );
});

Select.displayName = 'Select';

export default Select;
