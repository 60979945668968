import type { Config } from 'tailwindcss';
import plugin from 'tailwindcss/plugin';
import tailwindCssAnimate from 'tailwindcss-animate';

// We want each package to be responsible for its own content.
const config: Omit<Config, 'content'> = {
  theme: {
    extend: {
      backgroundImage: {
        'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
        'gradient-conic': 'conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))',
      },
      animation: {
        grow: 'grow 0.5s',
        gradient: 'gradient 2.5s ease-out infinite',
        'spin-slow': 'spin 2s linear infinite',
        ltr: 'ltr 2s linear infinite',
        slideUp: 'slideUp 300ms ease-out forwards',
        fadeIn: 'fadeIn 0.5s',
        slideInRight: 'slideInRight 0.5s',
        slideInLeft: 'slideInLeft 0.5s',
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
        'heart-grow': 'heart-grow 2s ease-in-out',
        floatLeft: 'float-left 2s ease-in-out',
        floatRight: 'float-right 2s ease-in-out',
      },
      keyframes: {
        grow: {
          '20%,100%': {
            transform: 'scale(1)',
          },
          '40%,80%': {
            transform: 'scale(1.25)',
          },
          '60%': {
            transform: 'scale(1.5)',
          },
        },
        gradient: {
          '0%,90%,100%': {
            'background-position': '100% 50%',
          },
          '45%': {
            'background-position': '0% 50%',
          },
        },
        ltr: {
          '0%,100%': {
            transform: 'translate(-2%, -50%)',
          },
          '25%': {
            transform: 'translate(7%, -50%)',
          },
          '50%': {
            transform: 'translate(2%, -50%)',
          },
          '75%': {
            transform: 'translate(-7%, -50%)',
          },
        },
        slideUp: {
          '0%': { opacity: 0, transform: 'translateY(1rem)' },
          '100%': { opacity: 1, transform: 'translateY(0)' },
        },
        fadeIn: {
          '0%': {
            opacity: '0',
          },
          '100%': {
            opacity: '1',
          },
        },
        slideInRight: {
          '0%': {
            transform: 'translateX(100%)',
            opacity: '0',
          },
          '100%': {
            transform: 'translateX(0)',
            opacity: '1',
          },
        },
        slideInLeft: {
          '0%': {
            transform: 'translateX(-100%)',
            opacity: '0',
          },
          '100%': {
            transform: 'translateX(0)',
            opacity: '1',
          },
        },
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
        floatRight: {
          '0%': {
            transform: 'translate(0, 0px) rotate(-45deg)',
            left: 0,
            opacity: 1,
          },
          '100%': {
            transform: 'translate(0, -50px) rotate(45deg)',
            left: -15,
            opacity: 0,
          },
        },
        floatLeft: {
          '0%': {
            transform: 'translate(0, 0px) rotate(-45deg)',
            right: 0,
            opacity: 1,
          },
          '100%': {
            transform: 'translate(0, -50px) rotate(45deg)',
            right: -15,
            opacity: 0,
          },
        },
        'heart-grow': {
          '0%, 100%': { transform: 'scale(1)' },
          '50%': { transform: 'scale(1.5)' },
        },
      },
      colors: {
        brand: {
          lighestpink: '#FDF7F9',
          lightpink: '#FAE6EE',
          lightpink2: '#FCF3F6',
          pink: '#EA98B8',
          darkpink: '#CB004F',
        },
        pink: {
          1: '#FFF8FB',
          2: '#FFF1F7',
          3: '#FFEAF4',
          4: '#FEDCEC',
          5: '#FCC6E1',
          6: '#F788BF',
          7: '#FF7DB7',
          8: '#FAF1F5',
          9: '#F8A0CC',
          10: '#F2BDD2',
          11: '#F7D6E3',
        },
        'neutral-grey': {
          100: '#E6E6E6',
          200: '#CCCCCC',
          300: '#B3B3B3',
          400: '#999999',
          500: '#808080',
          600: '#666666',
          700: '#4D4D4D',
          800: '#333333',
          900: '#1A1A1A',
          1000: '#000000',
          1100: '#757575',
        },
        red: {
          1: '#FF0D00',
        },
        state: {
          success: '#539A64',
          error: {
            primary: '#F44336',
            secondary: '#FFF6F8',
          },
        },
      },
    },
    fontFamily: {
      sans: ['var(--lato-font)', 'system-ui', 'sans-serif'], // Use the CSS variable for Lato
    },
    // match mantine breakpoints
    screens: {
      xxs: '24.875em',
      xs: '36em',
      sm: '48em',
      md: '62em',
      lg: '75em',
      xl: '88em',
      '2xl': '90em',
      'container': '112.5rem',
      '3xl': '118em',
    },
    fontSize: {
      xxs: '0.625rem',
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
    },
  },
  plugins: [
    tailwindCssAnimate,
    plugin(({ addBase, addVariant, addUtilities, theme }) => {
      addBase({
        '*': { fontFamily: 'var(--lato-font), system-ui, sans-serif' },
      });
      addVariant('shrunk', ':is([data-shrunk] &)');

      addUtilities({
        // hide scrollbars
        '.scrollbar-none': {
          scrollbarWidth: 'none',
          '-ms-overflow-style': 'none',
          '&::-webkit-scrollbar': { display: 'none' },
        },

        // increase the touch target of small buttons
        '.touch-target': {
          position: 'relative',
          '&::before': {
            content: '""',
            width: 'max(100%,2.75rem)',
            height: 'max(100%,2.75rem)',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          },
        },
        '.no-spin': {
          '-moz-appearance': 'textfield', // Firefox
          '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none', // Chrome, Safari, Edge
            margin: '0',
          },
        },

        '.next-link': {
          '@apply hover:underline decoration-[1.5px] underline-offset-[5px]': {},
        },

        '.radio-dot': {
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '0.6rem', // Smaller dot size
            height: '0.6rem', // Smaller dot size
            backgroundColor: theme('colors.pink.10'),
            borderRadius: '50%',
            display: 'none',
          },
          '&:checked::before': {
            display: 'block',
          },
        },
      });
    }),
  ],
};

export default config;
