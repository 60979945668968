import { CountryCallingCode, CountryCode, getCountryCallingCode } from 'libphonenumber-js/mobile';
import React from 'react';
import Select from '@ui/components/core/Form/Select';

const CountriesMapping: Array<{
  value: CountryCode;
  flag: string;
  callingCode: CountryCallingCode;
}> = [
  { value: 'US', flag: '🇺🇸 +1', callingCode: getCountryCallingCode('US') },
  { value: 'CA', flag: '🇨🇦 +1', callingCode: getCountryCallingCode('CA') },
  { value: 'AU', flag: '🇦🇺 +61', callingCode: getCountryCallingCode('AU') },
  { value: 'NZ', flag: '🇳🇿 +64', callingCode: getCountryCallingCode('NZ') },
  { value: 'GB', flag: '🇬🇧 +44', callingCode: getCountryCallingCode('GB') },
];

type PhoneCountrySelectProps = Omit<React.ComponentProps<'button'>, 'onChange'> & {
  value: CountryCode;
  onChange?: (newValue: CountryCode) => void;
};

const PhoneCountrySelect = React.forwardRef<HTMLButtonElement, PhoneCountrySelectProps>(
  ({ value, onChange, className, },ref) => {
    const selectedCountry = CountriesMapping.find((c) => c.value === value);

    return (
       <Select 
        value={selectedCountry?.value || ""}
        data={CountriesMapping.map((country) => {
          return { value: country.value.toString(), label: country.flag, disabled: false };
        })}
        onChange={(newValue:string) => {
          onChange?.(newValue as CountryCode);
        }}
        flagSelect
        className={className}
        classNames={{
          content:"w-fit min-w-[unset]",
          item: "w-full"
        }}
      />
    );
  },
);

PhoneCountrySelect.displayName = 'PhoneCountrySelect';

export default PhoneCountrySelect;
