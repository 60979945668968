import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { setCookie } from '@ui/hooks/useCookie';
import { Cart } from '@client-shopify/gql/storefront/api/queries/GetCart';
import SSIntelliSuggestCartView from '@ui/axios/searchSpring/intellisuggestCartView';
import { BagIcon, LockIcon } from '@ui/components/core';
import CartProductCard from '../../cards/CartProductCard/CartProductCard';
import env from '@ui/env';
import useCartStore from '@ui/store/cartStore';
import Link from 'next/link';
import { Drawer, DrawerBody, DrawerClose, DrawerContent, DrawerFooter, DrawerHeader } from '@ui/components/core/drawer';
import { Progress } from '@ui/components/core/progress';
import CartUpsellCarousel from '../../carousels/CartUpsellCarousel/CartUpsellCarousel';
import { Button } from "@ui/components/core/button"
import { useGetCart } from '@ui/hooks/useCartQuery';
import { useCartBuyerUpdate } from '@ui/hooks/useCartMutations';
import { useAuthCustomer } from '@ui/hooks/useAuthCustomer';
import { useCookie } from '@ui/hooks/useCookie';
import { useAuthStore } from '@ui/store/authStore';
import { fetchMultipassToken } from '@ui/utils/multipass';
import Money from '../../formatters/Money/Money';
import useCustomerCountry from '@ui/hooks/useCustomerCountry';

type CartDrawerProps = {
  cart: Cart | null | undefined;
  cartItemCount: number;
  opened: boolean;
  onClose: () => void;
};

const CartDrawer = ({ cartItemCount, opened, onClose }: CartDrawerProps): React.ReactElement => {
  const router = useRouter();
  const [loading, setLoading] = React.useState(false);
  const token = useAuthStore((state) => state.token);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const shopifyBuyerIp = useCookie('shopifyBuyerIp');
  const { fetchAuthCustomer } = useAuthCustomer();
  const { CartBuyerIdentityUpdateMutation } = useCartBuyerUpdate();
  const cartId = useCartStore((state) => state?.cartId);
  const cartUpdatedKey = useCartStore((state) => state?.cartUpdatedKey);

  const { data: getCartQueryResults, isFetching } = useGetCart({
    refreshKey: cartUpdatedKey || '',
    cartId: cartId || '',
  });

  const cartLineItems = getCartQueryResults?.cart?.cartLines || [];
  const cart = getCartQueryResults?.cart;

  useEffect(() => {
    if (!cartUpdatedKey || !getCartQueryResults?.cart?.cartLines || !getCartQueryResults?.cart?.cartLines?.length)
      return;

    let cartString = getCartQueryResults.cart.cartLines.map((cartProduct) => cartProduct.sku).join(',');

    setCookie('cart', cartString);
  }, [cartUpdatedKey, getCartQueryResults?.cart?.cartLines]);

  function handleClose() {
    onClose();
  }

  const customerCountry = useCustomerCountry();

  return (
    <Drawer open={opened} onClose={onClose} direction='right' dismissible={false}>
      <DrawerContent side="right" className='w-full max-w-[450px] rounded-none' overlayFunction={handleClose}>
        <DrawerHeader className='flex-col divide-y divide-black bg-white border-b border-black z-10'>
          <div className="flex items-center justify-between p-4 w-full">
            <div className="space-x-2 flex items-center">
              <p className="text-sm font-bold tracking-[0.01em]">MY BAG</p>
              <div className="text-xs">
                {cartItemCount} {cartItemCount === 1 ? 'item' : 'items'}
              </div>
            </div>
            <div className="flex items-center">
              <DrawerClose onClick={handleClose} />
            </div>
          </div>

          {cart && cartLineItems.length > 0 && customerCountry === env.NEXT_PUBLIC_REGION && (
            <div className="p-4 py-3 w-full">
              <div className="space-y-1.5">
                {cart.shipping.free ? (
                  <div className="text-xs text-center uppercase">
                    <b>YAY!</b> You get free {env.NEXT_PUBLIC_REGION} shipping.
                  </div>
                ) : (
                  <div className="text-xs text-center uppercase">
                    You are{' '}
                    <b>
                      {env.NEXT_PUBLIC_REGION}${cart.shipping.amountTillFree}
                    </b>{' '}
                    away from {env.NEXT_PUBLIC_REGION} free shipping.
                  </div>
                )}

                <div className="border border-black rounded-full bg-white p-0.5">
                  <Progress
                    className="transition-all ease-in-out duration-1000 bg-white w-full h-1"
                    value={cart.shipping.free ? 100 : (1 - parseFloat(cart.shipping.amountTillFree) / 150) * 100}
                  />
                </div>
              </div>
            </div>
          )}
        </DrawerHeader>
        <DrawerBody scrollArea={cartLineItems.length > 0}>
          {cartLineItems.length > 0 ? (
            <div className="p-4 py-0 divide-y-[0.6px] divide-black">
              {cartLineItems.map((product) => (
                <div key={product.variantId} className="py-4 md:py-6">
                  <CartProductCard product={product} onClose={onClose} />
                </div>
              ))}
            </div>
          ) : (
            <>
              {!isFetching && (
                <div className="h-full px-4 py-8 space-y-[26px] flex flex-col items-center justify-center">
                  <div className="space-y-2 text-center">
                    <BagIcon className="inline-block" width={72} height={72} />
                    <div className="font-normal tracking-[0.004em]">YOUR BAG IS EMPTY</div>
                    <div className="tracking-[0.4px]">{`Don't know where to start?`}</div>
                  </div>
                  <div className="space-y-2 w-[174px]">
                    <Button className="w-full" asChild>
                      <Link href="/collections/dresses">Dresses</Link>
                    </Button>
                    <Button className="w-full" asChild>
                      <Link href="/collections/shoes">Shoes</Link>
                    </Button>
                    <Button className="w-full" asChild>
                      <Link href="/collections/swim">Swim</Link>
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
        </DrawerBody>
        <DrawerFooter>
          {cartLineItems.length > 0 && (
            <div className="p-4 space-y-3 shadow-[0px_-4px_8px_0px_#00000014]">
              <div className="flex justify-between">
                <div className="font-normal text-sm uppercase">Subtotal</div>
                <div className="font-normal text-sm">
                  <Money value={cart?.subtotalAmount.amount} currency={cart?.subtotalAmount.currencyCode} />
                </div>
              </div>
              <Button
                asChild
                loading={loading}
                className="w-full inline-flex"
                onClick={async (e) => {
                  e.preventDefault();
                  let cartUrl;
                  try {
                    cartUrl = new URL(cart?.checkoutUrl);
                  } catch (err) {
                    cartUrl = cart?.checkoutUrl;
                  }
                  if (!cart) return;
                  setLoading(true);
                  if (isLoggedIn) {
                    try {
                      const authData = await fetchAuthCustomer(token ?? '');

                      if (!authData) {
                        router.push(cartUrl.href);
                        setLoading(false);
                        return;
                      }

                      try {
                        const cartBuyerUpdate = await CartBuyerIdentityUpdateMutation({
                          buyerIP: shopifyBuyerIp,
                          cartId: cart.id ?? '',
                          buyerIdentity: {
                            customerAccessToken: token ?? '',
                            countryCode: customerCountry,
                            email: authData.customerResult.data?.customer?.email || '',
                            phone: authData.customerResult.data?.customer?.phone || undefined,
                            deliveryAddressPreferences: [
                              {
                                deliveryAddress: {
                                  firstName: authData.customerResult.data?.customer?.firstName,
                                  lastName: authData.customerResult.data?.customer?.lastName,
                                  phone: authData.customerResult.data?.customer?.phone,
                                  address1: authData.customerResult.data?.customer?.defaultAddress?.address1,
                                  city: authData.customerResult.data?.customer?.defaultAddress?.city,
                                  country: authData.customerResult.data?.customer?.defaultAddress?.country,
                                  province: authData.customerResult.data?.customer?.defaultAddress?.province,
                                  zip: authData.customerResult.data?.customer?.defaultAddress?.zip,
                                },
                              },
                            ],
                          },
                        });

                        if (!cartBuyerUpdate || !cartBuyerUpdate?.cart.id) {
                          router.push(cartUrl.href);
                          setLoading(false);
                          return;
                        }

                        try {
                          const multipassToken = await fetchMultipassToken({
                            userToken: token ?? '',
                            checkoutUrl: cartUrl.href ?? '',
                          });

                          if (!multipassToken || !multipassToken?.redirectURL) {
                            router.push(cartUrl.href);
                            setLoading(false);
                            return;
                          }

                          setLoading(false);
                          return (window.location.href = multipassToken.redirectURL);
                        } catch (err) {
                          throw new Error(JSON.stringify(err));
                        }
                      } catch (err) {
                        throw new Error(JSON.stringify(err));
                      }
                    } catch (err) {
                      throw new Error(JSON.stringify(err));
                    }
                  } else {
                    router.push(cartUrl.href);
                    setLoading(false);
                    return;
                  }
                }}
              >
                <a className="hover:cursor-pointer">
                  <LockIcon className="size-4 mr-2" />
                  <span>Checkout</span>
                </a>
              </Button>
            </div>
          )}
          <div className="bg-pink-8 space-y-2 border-y border-black">
            <CartUpsellCarousel
              beaconPlacement="cart"
            />
          </div>
        </DrawerFooter>
        {cartLineItems.length > 0 && <SSIntelliSuggestCartView cartQueryResults={getCartQueryResults} />}
      </DrawerContent>
    </Drawer>
  );
};

export default CartDrawer;
